import { useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";
import { convertStringsToNumbers } from "../utils";
import { refetchInterval } from "../constant";
import { isMarketOpen } from "src/utils/time";
import { useContext } from "react";
import { SocketioContext } from "src/context/SocketioContext";

export const queryKey = {
  getTrades: "getTrades",
};

export async function getTrades(body={isAlerts: false}) {
  const url = 'options-data';
  delete body.isAlerts;
  if (!body.initial && !isMarketOpen() && !body.lastValueTime){
    return Promise.resolve([]);
  }
  if (body.initial){
    body.count = 200;
  }else if (body.lastValueTime ) {
    body.count = 50;
  }else{
    body.count = 5;
    body.isLive = true
  }
  delete body.initial
  let res = await axios.post(url, convertStringsToNumbers(body));
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}
const getEmpty = Promise.resolve([])

export const useGetTrades = (params) => {
  const {liveFeed} = useContext(SocketioContext)
  return useQuery({
    queryKey: [queryKey.getTrades],
    queryFn: () => liveFeed ? getTrades(params) : getEmpty(),
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true,
    enabled: true,
    refetchOnWindowFocus: true,
    staleTime: 8000,
    placeholderData: []
  });
};
